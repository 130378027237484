import React, { Component } from 'react';
import { Link, graphql } from 'gatsby';
import { connect } from 'react-redux';
import styled from 'react-emotion';
import ReactPixel from 'react-facebook-pixel';
import CurrencyFormat from 'react-currency-format';
import dotenv from 'dotenv';
import { toast } from 'react-toastify';
import Helmet from 'react-helmet';

import Layout from '../../components/layout';
import Container from '../../components/container';
import ProductList from '../../components/cart/product-list';

import 'typeface-dosis';

dotenv.config();

// Query result goes to this.props.data
export const query = graphql`
  query {
    allWifi {
      edges {
        node {
          productId
          title
          price
          duration
          discount_price
          description
          images{url,alt}
          thumbs{url,alt}
        }
      }
    }
    allReseller {
      edges {
        node {
          id
          title
          price
          duration
          discount_price
          description
          images{url,alt}
          thumbs{url,alt}
        }
      }
    }
    settings{
      data{
        generalDiscount
        deposit
      }
    }
  }
`

const FlexBox = styled.div`
  ${tw`flex flex-col md:flex-row`}
`

const Column = styled.div`
  ${tw`w-full md:w-1/2 mb-8`}
`

const ResponsiveCard = styled.div`
  ${tw`rounded p-4 shadow-md md:shadow-none`}
`

const Card = styled.div`
  ${tw`rounded p-4 shadow-md`}
`

const CheckoutButton = styled(Link)`
  ${tw`text-white rounded px-10 py-2 border-none block text-center`}
  text-decoration: none;
  background-color: #55BD22;
`

const DisabledButton = styled.div`
  ${tw`text-white rounded px-10 py-2 border-none block text-center bg-grey-light cursor-not-allowed`}
  text-decoration: none;
`

const InputContainer = styled.div`
  ${tw`pb-3`};
`

const InputLabel = styled.label`
  ${tw`text-sm`};
`

const SubTotal = styled.div`
  ${tw`text-2xl font-bold`}
  color: #E92028
`
const NotifEmpty = styled.div`
  ${tw`text-center`}
`
const Disco = styled.span`
  margin-left:15px;
  font-weight:600;
`
class CheckoutPage extends Component {
  _ismounted = false;
  state = {
    promoCode: '',
    totalDuration: 0,
  }

  updateSubtotal() {
    return (
      (this.props.shoppingCart.length > 0)
      ? this.props.shoppingCart.map(p => p.totalCost).reduce((a, b) => (a + b)) + ((this.props.shoppingCart.filter(p => p.type === 'wifi').length > 0) ? this.props.data.settings.data.deposit * this.props.numberOfDevices : 0)
      : 0
    );
  }
  getTotalBefore = () => {
    let total = 0;
    for (let i = 0; i < this.props.shoppingCart.length; i++) {
      total += this.props.shoppingCart[i].price * this.props.shoppingCart[i].quantity * (this.props.shoppingCart[i].type === 'wifi' ? this.props.numberOfDevices : 1);
    }
    return total + (this.props.data.settings.data.deposit * this.props.numberOfDevices);
  }
  getTotal() {
    let total = 0;
    for (let i = 0; i < this.props.shoppingCart.length; i++) {
      total += this.props.shoppingCart[i].totalCost * (this.props.shoppingCart[i].type === 'wifi' ? this.props.numberOfDevices : 1);
    }
    return total + (this.props.data.settings.data.deposit * this.props.numberOfDevices);
  }

  getTotalDuration() {
    let totalDuration = 0;
    if (this.props.shoppingCart.filter((p) => p.type === 'wifi').length > 0 ) {
      this.props.shoppingCart.forEach((p) => {
        if (p.type === 'wifi') {
          totalDuration += p.duration * p.quantity 
        }
      })
    } else {
      totalDuration = 3;
    }

    return totalDuration;
  }

  componentDidMount() {
    this._ismounted=true;
    ReactPixel.init(process.env.GATSBY_PIXEL_ID)
    ReactPixel.pageView()
    if (this._isMounted) {
      this.setState({
        promoCode: this.props.promoCode
      });
    }
  }
  componentWillUnmount() {
    this._ismounted=false;
  }
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Keranjang Belanja | Wifi Republic</title>
        </Helmet>
        <Container>
          <h1>Keranjang Belanja</h1>
          
          {
            (!this.props.shoppingCart || this.props.shoppingCart.length === 0)
            ? <NotifEmpty>Shopping cart is empty</NotifEmpty>
            :
              <>
                <FlexBox>
                  <Column>
                    <ResponsiveCard>
                      <ProductList
                        products={this.props.shoppingCart}
                        genDisc = {this.props.data.settings.data.generalDiscount}
                        deleteProduct={(key) => {
                          this.props.deleteProduct(key);
                          toast.warning('Produk telah dibuang dari keranjang belanja', {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true
                          });
                        }}
                      />
                    </ResponsiveCard>
                  </Column>

                  <Column>
                    <Card>
                      {this.props.data.settings.data.generalDiscount ?
                      <InputContainer>
                        <InputLabel>Potongan Harga:
                          <Disco>
                            <CurrencyFormat
                              value={this.getTotalBefore()-this.getTotal()}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'Rp '}
                            />
                          </Disco>
                        </InputLabel>
                      </InputContainer> : null }
                      <InputContainer>
                        <InputLabel>
                          Subtotal:
                        </InputLabel>
                        <SubTotal>
                          <CurrencyFormat
                            value={this.getTotal()}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'Rp '}
                          />
                        </SubTotal>
                      </InputContainer>
                      
                      {
                        this.getTotalDuration() >= 3 ? (
                          <CheckoutButton 
                            to="/checkout"
                          >
                            Lanjutkan Transaksi
                          </CheckoutButton>
                        ) : (
                          <DisabledButton>
                            Durasi Pemesanan Wifi Minimal 3 Hari
                          </DisabledButton>
                        )
                      }
                    </Card>
                  </Column>

                </FlexBox>
              </>
          }
          
        </Container>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    shoppingCart: state.shoppingCart,
    numberOfDevices: state.numberOfDevices,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteProduct: (key) => dispatch({ type: 'DEL_PRODUCT', key }),
    changeOfDevices: (qty) => dispatch({ type: 'CHANGE_NUMBER_OF_DEVICES', qty }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);